import styled from 'styled-components'
import { Image } from '../ImageGrid/ImageGridStyles'

export const TreeSpecContainer = styled.section`
    width: 90%;
`;

export const TreeName = styled.h3`
    margin-bottom: 12px;
    margin-top: 8px;
    font-size: 32px;
    @media(min-width: 700px) {
        font-size: 50px;
    }
`

export const SpecBlock = styled.ul`
    margin: 0 0 40px;

    @media(min-width: 700px) {
        padding-right: 40px;
    }

    li {
        &:first-child {
            p {
                margin: 0;
            }
        }
    }

    &:first-child {
        margin-top: 48px;
    }
`;

export const SpecGrid = styled.div`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;

    @media(min-width: 700px) {
        grid-gap: 20px;
    }

    ${Image} {
        &:last-child {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row: auto;
        }
    }
`;