import React from 'react'
import {
    TreeSpecContainer,
    TreeName,
    SpecBlock,
    SpecGrid
} from "./TreeSpecsStyles"
import {
    Eyebrow
} from '../../styles'

// Images
import GridImageOne from "../../images/olive-tall-one.jpg"
import GridImageTwo from "../../images/olive-tall-two.jpg"
import GridImageThree from "../../images/olive-wide-one.jpg"
import ImageGrid from '../ImageGrid'

const TreeSpecs = () => {
    
    const gridImages = [
        {
          imgSrc: `${GridImageOne}`,
          altText: "Alt text 1"
        },
        {
          imgSrc: `${GridImageTwo}`,
          altText: "Alt text 2"
        },
        {
          imgSrc: `${GridImageThree}`,
          altText: "Alt text 3"
        }
    ]
    
    const hardiness = [
        {
            copy: "USDA Zone 10a: to -1.1 ºC (30 ºF)"
        },
        {
            copy: "USDA Zone 10b: to 1.7 ºC (35 ºF)"
        },
        {
            copy: "USDA Zone 11: above 4.5 ºC (40 ºF)"
        }
    ]

    const height = [
        {
            copy: "Over 40 ft. (12m)"
        }
    ]

    const spacing = [
        {
            copy: "12-15 ft. (3.6-4.7m)"
        }
    ]

    const sunExposure = [
        {
            copy: "Full Sun"
        }
    ]

    const bloomColor = [
        {
            copy: "White/Near White"
        }
    ]

    const bloomTime = [
        {
            copy: "Mid-Summer"
        }
    ]

    const foliage = [
        {
            copy: "Evergreen"
        }
    ]

    const danger = [
        {
            copy: "Pollen may cause allergic reaction."
        }
    ]

    const waterNeeds = [
        {
            copy: "Low. Drought-tolerant; suitable for xeriscaping "
        }
    ]

    const otherDetails = [
        {
            copy: "Young trees have smooth gray bark, but trunks and branches will gnarl somewhat picturesquely with age. Opposite, elliptic to lance shaped leaves (to 3” long) are gray-green above and  silver-green beneath. Very small white flowers bloom in panicles (to 2” long) in summer on stems from the leaf axils."
        },
        {
            copy: "Flowers are fragrant. Flowers give way to oval green drupes (olives to 1.5” long) which ripen to black. Each olive contains a single pit. Olives from this species are commercially harvested as eating olives and for production of olive oils."
        }
    ]

    return (
        <TreeSpecContainer className="margin-center top-padding-less bottom-padding-less">
            <div className="cols cols--twothirds">
                <div className="col">
                    <TreeName>Ancient Olive Trees</TreeName>
                    <Eyebrow>Olea europaea</Eyebrow>
                    <div>
                        <SpecBlock>
                            <li><Eyebrow>Hardiness:</Eyebrow></li>
                            {hardiness.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Height:</Eyebrow></li>
                            {height.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Spacing:</Eyebrow></li>
                            {spacing.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Sun Exposure:</Eyebrow></li>
                            {sunExposure.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Bloom Color:</Eyebrow></li>
                            {bloomColor.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Bloom Time:</Eyebrow></li>
                            {bloomTime.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Foliage:</Eyebrow></li>
                            {foliage.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Danger:</Eyebrow></li>
                            {danger.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Average Water Needs:</Eyebrow></li>
                            {waterNeeds.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                        <SpecBlock>
                            <li><Eyebrow>Other details:</Eyebrow></li>
                            {otherDetails.map(spec => (
                                <li>{spec.copy}</li>
                            ))}
                        </SpecBlock>
                    </div>
                </div>
                <div className="col">
                    <SpecGrid>
                        {gridImages.map(img => (
                            <ImageGrid
                                imagePath={img.imgSrc}
                                key={img.altText}
                            />
                        ))}
                    </SpecGrid>
                </div>
            </div>
        </TreeSpecContainer>
    )
}

export default TreeSpecs
