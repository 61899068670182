import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import TextBlock from '../components/TextBlock'
import TreeSpecs from '../components/TreeSpecs'
import ContactCallout from '../components/ContactCallout'
import { Helmet } from 'react-helmet'

const OurTreesPage = () => {

    const data = useStaticQuery(graphql`
    {
        file(relativePath: {eq: "hero-bg.jpg"}) {
            name
            sharp: childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF],
                layout: FULL_WIDTH,
                quality: 70,
                placeholder: BLURRED
              )
            }
        }
    }
  `)

    const heroImg = data.file.sharp.gatsbyImageData
    
    const introBlockCopy = [
        {
            para: "At Ancient Olives, we take great care to provide a unique and  superior product and service. Our ancient and old growth olive trees  have been hand selected by the partners for their historic and  architectural aesthetics and value. We deliver them to your project and  walk you through the steps on how to prepare for, plant, and maintain  your historic olive trees for success."
        }
    ]

    const columnOneCopy = [
        {
            para: "Our owners are a family partnership that has been working together for many years. Eddie Flores and Sean Lockyer have been in the Landscaping and Design businesses for over four decades combined."
        },
        {
            para: "Eddie’s knowledge of olive tree anatomy and biology as well as his years of expertise in the landscape business makes him well suited to bringing you a quality product that is unsurpassed in the industry."
        }
    ]

    const columnTwoCopy = [
        {
            para: "Sean Lockyer has been hand selecting olive trees for his various architectural projects for the last decade and a half. He brings his years of experience in Architecture to the detailed selection of the highest quality and most dramatic olive tree trunks and branch structure that your project requires."
        }
    ]

    return (
        <Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
                <title>Ancient Olives | Our Trees</title>
                <meta name="description"
                    content="At Ancient Olives, we take great care to provide a unique and 
                    superior product and service."
                />
            </Helmet>
            <Hero
                heading={`Our Trees`}
                styleClass="short"
                heroImage={heroImg}
            />
            <section className="top-padding bottom-padding">
                <TextBlock
                    text={introBlockCopy.map(text => <p key={text.para}>{text.para}</p>)}
                    alignmentClass={`centered`}
                    styleClass={`lg`}
                />
            </section>
            <section className="cols cols--50 bg-tan margin-center top-padding-less bottom-padding-less">
                <div className="col">
                    {columnOneCopy.map(copy => (
                        <p>{copy.para}</p>
                    ))}
                </div>
                <div className="col">
                    {columnTwoCopy.map(copy => (
                        <p>{copy.para}</p>
                    ))}
                </div>
            </section>
            <section className="top-padding-less">
                <TreeSpecs />
            </section>
            <ContactCallout heading={`Need help with choosing the right tree?`} />
        </Layout>
    )
}

export default OurTreesPage
